<template>
  <div class="rule-card">
    <div class="header">
        <h1>Regra #{{rule.number}} - {{rule.name}}</h1>

        <span class="d-flex align-items-center">
          <toggle-button
            v-can="'RepMedReg3'"
            class="autoFillBtn mr-2"
            :width="40"
            :value="Boolean(rule.active)"
            :sync="true"
            :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
            @change="updateRule"
          />

          <Edit 
            v-can="'RepMedReg4'"
            class="edit mr-1" 
            @click="openRuleModal(rule)" 
          />

          <Config 
            v-can="'RepMedReg4'"
            class="settingIcon mr-1" 
            @click="$router.push(`/regras_repasse/${rule.id}/definicoes`)"
          />

          <ChevronDown class="icon" v-if="!expanded" @click="expanded = true"/>
          <ChevronUp class="icon" v-else @click="expanded = false"/>
        </span>
    </div>

    <div v-if="expanded" class="body">
        <b-row class="body__wrapper">
            <b-col cols="12" class="body__favored">
              <h3 class="expanded-title">Favorecidos</h3>              
              <p class="expanded-text">{{rule.setting.beneficiaries_count}} favorecidos</p>
            </b-col>

            <b-col cols="12">  
              <hr class="body__divider" />
            </b-col>

            <b-col cols="12" class="body__procedures">
              <h3 class="expanded-title">Procedimentos e produtos</h3>
              <p class="expanded-text">{{rule.setting.items_count}} itens selecionados</p>
            </b-col>
        </b-row>
    </div>
    
    <hr v-if="expanded" class="body__divider" />

    <div class="d-flex px-2 pb-3 pt-3">
        <span class="rule-info ml-2">
          <span>Data de vigência: </span> 
          {{moment(rule.specification.rule_from).format('DD/MM/YYYY')}}
        </span>
    </div>
  </div>
</template>

<script>
import api from '@/modules/onlending/api.js'

export default {
  name: 'Rule',
  props: {
    rule: {
      type: Object,
      required: true
    },
    openRuleModal: {
      type: Function,
      required: true
    }
  },
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    ChevronUp: () => import('@/assets/icons/chevron-up.svg'),
    Config: () => import('@/assets/icons/settings.svg'),
    Edit: () => import('@/assets/icons/edit.svg')
  },
  data() {
    return {
      expanded: false
    }
  },
  methods: {
    updateActive() {
      this.rule.active = !this.rule.active
    },
    async updateRule() {
      const isLoading = this.$loading.show()
      try {
        this.updateActive()
        await api.updateOnlendingRule(
          this.rule.id,
          this.rule
        )
        this.$emit('updateRule')
        if (this.rule.active) {
          this.$toast.success('Regra ativada com sucesso!')
        } else {
          this.$toast.success('Regra desativada com sucesso!')
        }
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .rule-card { 
    border: 1px solid var(--neutral-300);
    border-radius: 8px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    border-radius: 8px 8px 0px 0px;
    background: #F4F5FB;
    padding: 16px 16px 8px 16px;
    border-bottom: 1px solid var(--neutral-300);

    h1 {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      color: var(--type-active);
    }
  }

  .body {
    &__wrapper {
      margin: 0;
    }
    &__favored {
      padding: 1rem;
    }
    &__divider {
      padding: 0;
      margin: 0;
    }
    &__procedures {
      padding: 1rem;
    }
  }

  .edit {
    width: 20px;
    height: 20px;
    cursor: pointer;
    stroke:var(--blue-600);    

    :hover{
      stroke:gray;    
    }
  }

  .settingIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    stroke:var(--blue-600);  
  }

  .icon {
    width: 28px;
    height: 28px;
    cursor: pointer;

    :hover{
      stroke:gray;    
    }
  }

  .rule-info{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 550;
    font-size: 15px;
    line-height: 150%;

    span{
      font-weight: 700;
    }
  }

  .expanded-title{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 150%;
    color: var(--type-active);
  }

  .expanded-text{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    color: var(--type-active);
    margin: 0;
  }
</style>